export const sol = [
    { label: "Building brands",label_ar:'بناء العلامات التجارية    ' },
    { label: "Optimizing websites for search engines",label_ar:'تحسين مواقع الويب لمحركات البحث    ', },
    { label: "Social media management",label_ar:'إدارة وسائل التواصل الاجتماعي    ' },
    { label: "Writing creative content",label_ar:'كتابة المحتوى الإبداعي    ' },
    { label: "Email campaigns",label_ar:'حملات البريد الإلكتروني    ' },
    { label: "SMS marketing",label_ar:'تسويق الرسائل القصيرة    ' },
    { label: "Graphic design",label_ar:'التصميم الجرافيكي    ' },

  ];





